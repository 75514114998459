:root{


    /* Paleta colores */
    --color-pallete-1: #c79747;
    --color-pallete-2: #aa6900;
    --color-pallete-3: #FFFFFF;
    --color-pallete-4: #e1a73f;
    --color-pallete-5: #a09f9f;
  
    /* ----------------------- */
  
    /* Generales */
    --color-fondo-loader: var(--color-pallete-3);
    --color-loader: var(--color-pallete-1);
    --color-fondo-body: var(--color-pallete-3);
    --color-sombra-container-body: var(--color-pallete-5);
    --color-scroll-track: var(--color-pallete-3);
    --color-scroll-thumb: var(--color-pallete-1);
    --color-scroll-thumb-hover: var(--color-pallete-2);
    --color-fondo-secciones-1: var(--color-pallete-3);
    --color-fondo-secciones-2: var(--color-pallete-3);
    --color-titulos: var(--color-pallete-2);
    --color-subtitulos: var(--color-pallete-4);
    --color-fondo-btns: var(--color-pallete-4);
    --color-fondo-btns-hover: var(--color-pallete-1);
    --color-txt-btns: var(--color-pallete-3);
    --color-txt-btns-hover: var(--color-pallete-3);
    --color-anim-adorno-titulo: var(--color-pallete-1);
    --color-adornos-divisor: var(--color-pallete-3);
    --color-backdrop-modal: var(--color-pallete-3);
    --color-titulo-modal: var(--color-pallete-3);
    --color-adorno-titulo-modal: var(--color-pallete-3);
    --color-bordes-adornos-modal: var(--color-pallete-1);
    --color-fondo-col-modal: var(--color-pallete-3);
    --color-iconos-modales: var(--color-pallete-3);
    --color-textos-modales: var(--color-pallete-3);
    --color-x-cierre-modales: var(--color-pallete-3);
    --color-hover-x-cierre-modales: var(--color-pallete-4);
    --color-titulo-modal-paises: var(--color-pallete-1);
    --color-btns-idiomas: var(--color-pallete-2);
    --color-txt-btns-idiomas: var(--color-pallete-3);
    --color-hover-btns-idiomas: var(--color-pallete-1);
    --color-hover-txt-btns-idiomas: var(--color-pallete-3);
    --color-label-forms: var(--color-pallete-3);
    --color-bordes-inputs-forms: var(--color-pallete-4);
    --color-placeholders-forms: var(--color-pallete-3);
    --color-checkbox: var(--color-pallete-2);
    --color-inputs-form: var(--color-pallete-3);
    --color-input-focus: var(--color-pallete-3);
    --color-titulo-form-enviado: var(--color-pallete-3);
    --color-texto-form-enviado: var(--color-pallete-3);
    --color-iniciales: var(--color-pallete-1);
    --color-nombres: var(--color-pallete-2);
    --color-ampersand: var(--color-pallete-2);
  
    /* ----------------------- */
  
    /* Musica */
    --color-backdrop-modal-musica: var(--color-pallete-3);
    --color-circulos-anim-reproductor: var(--color-pallete-2);
    --color-nota-anim-reproductor: var(--color-pallete-1);
    --color-esquinas-modal-musica: var(--color-pallete-2);
    --color-txt-bienvenida: var(--color-pallete-1);
    --color-txt-recomienda-musica: var(--color-pallete-1);
    --color-adorno-modal-musica: var(--color-pallete-1);
  
    /* Portada */
    --color-fecha-portada: var(--color-pallete-1);
    --color-esquinas-portada: var(--color-pallete-1);
    --color-frase-portada: var(--color-pallete-2);
    --color-adorno-frase-portada: var(--color-pallete-1);
  
    /* Cuenta regresiva */
    --color-titulo-cuenta-regresiva: var(--color-pallete-3);
    --color-numeros-cuenta-regresiva: var(--color-pallete-3);
    --color-texto-cuenta-regresiva: var(--color-pallete-3);
    --color-anim-cta-regresiva: var(--color-pallete-3);
    --color-divisores-cta-regresiva: var(--color-pallete-3);
    --color-msjfinal-cta-regresiva: var(--color-pallete-3);
  
    /* Eventos */
    --color-bordes-adornos-evento: var(--color-pallete-1);
    --color-fondo-col-evento: var(--color-pallete-3);
    --color-anim-eventos: var(--color-pallete-3);
    --color-titulos-eventos: var(--color-pallete-3);
    --color-subtitulos-eventos: var(--color-pallete-3);
    --color-adorno-titulo-eventos: var(--color-pallete-3);
    --color-textos-eventos: var(--color-pallete-3);
  
    /* Galeria */
    --color-anim-galeria: var(--color-pallete-3);
    --color-fondo-polaroid: var(--color-pallete-3);
    --color-fondo-polaroid-centro: var(--color-pallete-4);
    --color-bullet-galeria: var(--color-pallete-3);
    --color-modal-galeria: var(--color-pallete-3);
  
    /* Fiesta */
    --color-fondo-item-fiesta: var(--color-pallete-3);
    --color-titulos-cards: var(--color-pallete-3);
    --color-txt-cards: var(--color-pallete-3);
    --color-anim-cards: var(--color-pallete-3);
    --color-bordes-adornos-cards: var(--color-pallete-1);
  
    /* Regalos */
    --color-anim-regalos: var(--color-pallete-3);
  
    /* Instagram */
    --color-anim-instagram: var(--color-pallete-1);
    --color-fondo-hashtag: var(--color-pallete-1);
    --color-link-instagram: var(--color-pallete-3);
  
    /* Footer */
    --color-links-footer: var(--color-pallete-3);
    --color-links-footer-hover: var(--color-pallete-4);
    --color-fondo-firma: var(--color-pallete-3);
    --color-txt-firma: var(--color-pallete-2);
    --color-link-firma: var(--color-pallete-2);
    --color-link-firma-hover: var(--color-pallete-1);
  
    
    /* Vars para degradez en secciones (desde 4/9/24) */
  
    --color-degradez-1: var(--color-pallete-1);
    --color-degradez-2: var(--color-pallete-2);
    --color-anim-adorno-titulo-galeria: var(--color-pallete-3);
    --color-anim-adorno-titulo-regalos: var(--color-pallete-3);
    --color-titulo-galeria: var(--color-pallete-3);
    --color-titulo-regalos: var(--color-pallete-3);
    --color-subtitulo-galeria: var(--color-pallete-3);
    --color-subtitulo-regalos: var(--color-pallete-3);
    --color-iniciales-footer: var(--color-pallete-3);
    --color-ampersand-footer: var(--color-pallete-4);
    --color-nombres-footer: var(--color-pallete-3);
    
    /* end Vars para degradez en secciones (desde 4/9/24) */
    
  }