.st1 {
    fill: none;
    stroke: #AF8566;
    stroke-width: 1.5584;
    stroke-miterlimit: 10;
}

.st0 {
    fill: none;
    stroke: #AF8566;
    stroke-width: 1.7553;
    stroke-miterlimit: 10;
}